import type { GetOrderType } from '~/composables/order/types/OrderType';
import type { ComputedRef, Ref } from 'vue';

type UserOrderStoreType = {
	userOrdersList:Ref<GetOrderType[] | undefined>;
	setUserOrders:(userOrders:GetOrderType[])=>void;
	orderAmount:ComputedRef<number>;
}

export const userOrdersStore = defineStore('user-orders', ():UserOrderStoreType => {
  const userOrdersList = ref<GetOrderType[] | undefined>(undefined);

  function setUserOrders (userOrders:GetOrderType[]) {
    userOrdersList.value = userOrders;
  }

  const orderAmount = computed(() => {
    if (userOrdersList.value) {
      return userOrdersList.value.length;
    }
    return 0;
  });

  return {
    userOrdersList,
    orderAmount,
    setUserOrders
  };
});
