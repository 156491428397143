import type {
  GetOrderType,
  MakeOrderType,
  OrderDeliveryType,
  OrderPaymentType,
  OrderReqType
} from '~/composables/order/types/OrderType';
import type { ServerResponseCommonType } from '~/types/commons';
import { userOrdersStore as store } from '~/stores/user-oreders';

import type { ComputedRef, Ref } from 'vue';

type UseOrderType = {
	makeOrder:(options:OrderReqType) => Promise<MakeOrderType | null>;
	getPaymentTypes:()=>Promise<OrderPaymentType[]>;
	getDeliveryTypes:()=>Promise<OrderDeliveryType[]>;
	getOrders:()=> Promise<GetOrderType[]>;
	userOrdersList:Ref<GetOrderType[] | undefined>;
	orderAmount:ComputedRef<number>;
}

export function useOrder ():UseOrderType {
  const { $api } = useNuxtApp();
  const userOrderStore = store();
  const { orderAmount, userOrdersList } = storeToRefs(userOrderStore);

  async function makeOrder (options:OrderReqType):Promise<MakeOrderType | null> {
    const res = await $api<ServerResponseCommonType<MakeOrderType>>('order/makeOrder', {
      method: 'POST',
      body: { ...options }
    }).catch((err) => { throw err; });
    return res && res.data ? res.data : null;
  }

  async function getPaymentTypes () {
    const res = await $api<OrderPaymentType[]>('order/getPaymentTypes').catch((err) => { throw err; });
    return res && res.length ? res : [];
  }

  async function getDeliveryTypes () {
    const res = await $api<OrderDeliveryType[]>('order/getDeliveryTypes').catch((err) => { throw err; });
    return res && res.length ? res : [];
  }

  async function getOrders () {
    const res = await $api<ServerResponseCommonType<GetOrderType[]>>('order/getOrders').catch((err) => { throw err; });
    if (res && res.data.length) {
      userOrderStore.setUserOrders(res.data);

      return res.data;
    }
    return [];
  }

  return {
    orderAmount,
	  userOrdersList,
	  getOrders,
	  makeOrder,
	  getDeliveryTypes,
	  getPaymentTypes
  };
}
